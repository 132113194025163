<template>
    <div :class="{'lc-loading' : loading}">

        <div class="flex justify-center items-start max-w-2xl m-auto">
            <div class="hidden sm:block w-1/5 bg-blue p-2">
                <p class="w-full text-center p-2 text-white"> Refine Search</p>

                <div class="my-2">
                    <p class="text-white text-sm">Amenities:</p>
                    <label v-for="amenity in amenitiesToFilter" class="text-sm my-1">
                        <input type="checkbox" :id="amenity" :value="amenity" v-model="amenityFilters"
                               @change="setAmenityFilters">
                        {{amenity}}</label>
                </div>

                <div class="my-2" v-if="categoriesToFilter.length > 1">
                    <p class="text-white text-sm">Locations:</p>
                    <label v-for="category in categoriesToFilter" class="text-sm my-1">
                        <input type="checkbox" :id="category" :value="category" v-model="categoryFilters"
                               @change="setCategoryFilters">
                        {{category}}</label>
                </div>

                <div class="my-2">
                    <p class="text-white text-sm">Sort By:</p>
                    <label for="bedrooms" class="text-sm my-1">
                        <input type="radio" id="bedrooms" value="bedrooms" v-model="sortType" @change="setSortType">
                        Bedrooms</label>
                    <label for="bathrooms" class="text-sm my-1">
                        <input type="radio" id="bathrooms" value="bathrooms" v-model="sortType" @change="setSortType">
                        Bathrooms</label>
                    <label for="price" class="text-sm my-1">
                        <input type="radio" id="price" value="weekly_winter_rate" v-model="sortType" @change="setSortType">
                        Price</label>
                </div>

            </div>

            <div class="w-full ml-auto sm:ml-6">
                <div class="text-center p-3">
                    <h1 class="page-title">Seabluffe Rentals</h1>
                </div>
                <div class="sm:flex sm:flex-row flex-col justify-between items-center p-4 text-left">
                    <div class="text-grey-dark font-light">Showing {{availableProperties.length}} Results:
                        <button @click="toggleModal"
                                class="bg-white inline-flex justify-center items-center mx-2 p-2 rounded hover:shadow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" class="feather feather-share-2 mx-1">
                                <circle cx="18" cy="5" r="3"/>
                                <circle cx="6" cy="12" r="3"/>
                                <circle cx="18" cy="19" r="3"/>
                                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"/>
                                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"/>
                            </svg>
                            Share
                        </button>
                    </div>
                    <div class="flex-1">
                        <property-search-form @updateSearchQuery="searchProperties"/>
                    </div>
                </div>
                <div class="flex flex-wrap pb-10" v-if="availableProperties.length >= 1">
                    <property-list-card v-for="property in availableProperties" :key="property.id" :property="property"
                                        :show-rates="true"></property-list-card>
                </div>
                <div class="text-center py-6 h-full" v-else v-html="inventoryMessage">
                </div>
            </div>
        </div>

        <div v-if="showModal" class="top-0 fixed h-screen w-full flex flex-col items-center justify-center"
             style="background: rgba(255,255,255,.8);" @click="toggleModal">
            <div class="h-screen w-full absolute flex items-center justify-center bg-modal">
                <div class="bg-white rounded shadow p-8 m-4 max-w-xs max-h-full text-center overflow-y-scroll">
                    <div class="mb-4">
                        <h1>Share your search results!</h1>
                    </div>
                    <div class="my-4 text-center">
                        <input type="text" class="w-full p-2 bg-grey-lightest border border-grey-lighter rounded"
                               :value="generateSearchUrl()">
                        <br>
                        <p class="text-xs p-2 uppercase cursor-pointer clipboard-button"
                           @click="copyUrl" :data-clipboard-text="generateSearchUrl()">Click to copy url</p>
                    </div>
                    <div class="mb-8">
                        <div class="p-4 bg-white rounded">
                            <div class="sharethis-inline-share-buttons" :popup="true"
                                 :data-url="generateSearchUrl()"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import PropertySearchForm from './PropertySearchForm';
    import PropertyListCard from './PropertyListCard';
    import PropertyMap from './PropertyMap';

    export default {
        data() {
            return {
                showModal: false,
                selectedDates: null,
                guests: 2,
                availableProperties: {},
                loading: false,
                sortType: null,
                amenitiesToFilter: [],
                amenityFilters: [],
                categoriesToFilter: [],
                categoryFilters: [],
                inventoryMessage: '<h3>Searching For Properties..</h3>'
            }
        },
        props: {
            default_properties: Array,
            category: String,
        },
        methods: {
            copyUrl() {
                let clipboard = new window.clipboard('.clipboard-button');
                let message = this.$toasted;

                clipboard.on('success', function (e) {
                    message.show("Copied to clipboard!", {duration: 1000});
                    e.clearSelection();
                });

                clipboard.on('error', function (e) {
                    message.error("can't copy!", {duration: 1000});
                });

                // this.showModal = false;
            },
            toggleModal() {
                this.showModal = !this.showModal;
                window.__sharethis__.initialize()
            },
            generateSearchUrl() {
                let base_url = window.location.origin + window.location.pathname + "?";
                let arrival = null;
                let departure = null;
                let guests = null;
                if (sessionStorage.searchQuery) {
                    let query = JSON.parse(sessionStorage.searchQuery, this.dateTimeReviver);
                    arrival = '&arrival=' + this.$moment(query.arrival).format('YYYY-MM-DD');
                    departure = '&departure=' + this.$moment(query.departure).format('YYYY-MM-DD');
                    guests = '&guests=' + query.guests;
                }
                let amenities = 'amenities=' + this.amenityFilters;
                let categories = '&categories=' + this.categoryFilters;
                let sort = '&sort=' + this.sortType;
                return base_url + amenities + categories + sort + arrival + departure + guests;
            },
            getAvailableProperties() {
                if (sessionStorage.searchQuery) {
                    let query = JSON.parse(sessionStorage.searchQuery, this.dateTimeReviver);
                    this.searchProperties(query);
                } else {
                    this.availableProperties = this.sortProperties(this.default_properties)
                }
            },
            getAmenitiesToFilter() {
                this.amenitiesToFilter = [].concat.apply([], this.default_properties.map(property => property.amenities.map(amenity => amenity.name)).filter(value => Object.keys(value).length !== 0)).filter((v, i, a) => a.indexOf(v) === i).reverse();
            },
            getCategoriesToFilter() {
                this.categoriesToFilter = [...new Set(this.default_properties.map(property => property.category))];
            },
            getSearchParams(param) {
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);
                return params.get(param);
            },
            filterAmenities(filteredProperties) {
                if (sessionStorage.amenityFilters) {
                    this.amenityFilters = sessionStorage.amenityFilters.split(',');
                }

                if (this.amenityFilters.length) {
                    filteredProperties = filteredProperties.filter(property =>
                        this.amenityFilters.every(filter => property.amenities.map(amenity => amenity.name).indexOf(filter) > -1));
                }
                return filteredProperties;
            },
            filterCategories(filteredProperties) {
                if (sessionStorage.categoryFilters) {
                    this.categoryFilters = sessionStorage.categoryFilters.split(',');
                }

                if (this.categoryFilters.length) {
                    filteredProperties = filteredProperties.filter(property =>
                        this.categoryFilters.indexOf(property.category) > -1
                    );
                }
                return filteredProperties;
            },
            setAmenityFilters() {
                sessionStorage.amenityFilters = this.amenityFilters;
                this.getAvailableProperties();
            },
            setCategoryFilters() {
                sessionStorage.categoryFilters = this.categoryFilters;
                this.getAvailableProperties();
            },
            setSortType() {
                sessionStorage.sortType = this.sortType;
                this.getAvailableProperties();
            },
            setFiltersFromUrl() {
                if (this.getSearchParams('amenities')) {
                    this.amenityFilters = this.getSearchParams('amenities').split(',');
                    sessionStorage.amenityFilters = this.amenityFilters;
                }
                if (this.getSearchParams('categories')) {
                    this.categoryFilters = this.getSearchParams('categories').split(',');
                    sessionStorage.categoryFilters = this.categoryFilters;
                }
                if (this.getSearchParams('sortType')) {
                    this.sortType = this.getSearchParams('sortType');
                    sessionStorage.sortType = this.sortType;
                }
            },
            searchProperties(query) {
                this.loading = true;
                axios.post('/api/properties/getAvailability', query)
                    .then(response => {
                        this.loading = false;
                       
                        this.availableProperties = this.sortProperties(response.data);
                        if (this.availableProperties.length < 1) {
                            this.inventoryMessage = '<h3>No properties matched your search.</h3><h4>Please adjust your search or contact us for help.</h4>';
                        }
                    })
                    .catch(error => {
                        this.$alertErrors(error);
                    });
                return this.availableProperties;
            },

            sortProperties(properties) {
                let filteredProperties = properties;

                if (sessionStorage.searchQuery) {
                    filteredProperties = filteredProperties.filter(property => property.rates.subtotal > 0);
                }

                filteredProperties = filteredProperties.filter(property => property.rentable !== 0);


                filteredProperties = this.filterAmenities(filteredProperties);
                filteredProperties = this.filterCategories(filteredProperties);

                if (this.category) {
                    filteredProperties = filteredProperties.filter(property =>
                        this.category.indexOf(property.category) > -1
                    );
                }

                // let sortedProperties = _.sortBy(filteredProperties, [function(p) { return p.title.replace(/[0-9]/g, '').replace(/ /g,''); }]);

                let sortedProperties = filteredProperties.sort(function(a, b) {
                    var textA = a.title.toUpperCase();
                    var textB = b.title.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                if (sessionStorage.sortType) {
                    this.sortType = sessionStorage.sortType;
                    sortedProperties = _(filteredProperties).sortBy(sessionStorage.sortType).value();
                } else if (this.sortType) {
                    sortedProperties = _(filteredProperties).sortBy(this.sortType).value();
                }

                return _(sortedProperties).sortBy('length').value();
            },
        },
        mounted() {
            this.getAvailableProperties();
            this.setFiltersFromUrl();
        },
        created() {
            this.getAmenitiesToFilter();
            this.getCategoriesToFilter();
            this.getSearchParams();
        },
        components: {
            PropertySearchForm,
            PropertyListCard,
            PropertyMap
        }
    };
</script>

<style lang="scss">


    label {
        display: inline-block;
        cursor: pointer;
        padding: 2px 10px;
        margin: 0 .25rem;
        font-size: 1.2em;
        color: white;
    }

    input[type=radio]:checked + label {
        color: white;
        background: #174656;
    }
</style>
